import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SENSOR_DATA_API } from "./Api";
import axios from "axios";

class Result {
    constructor({
        param_id: param_id,
        param_name: param_name,
        unit_name: unit_name,
        reading_value: reading_value,
        reading_timestamp: reading_timestamp,
    }) {
        this.param_id = param_id;
        this.param_name = param_name;
        this.unit_name = unit_name;
        this.reading_value = reading_value;
        this.reading_timestamp = reading_timestamp;

    }
}

const StickyCarousel = () => {
    const sliderRef = useRef(null);
    const [result, setResult] = useState([]);

    useEffect(() => {
        const fetchReadingsForParameters = async () => {
            try {
                const response = await axios.get(SENSOR_DATA_API);
                const data = response.data;
                const resultList = data.map((item) => new Result(item));
                setResult(resultList);
                console.log(resultList);
            } catch (error) {
                console.error('Error fetching sensor data:', error);
            }
        };

        fetchReadingsForParameters();

        const intervalId = setInterval(fetchReadingsForParameters, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const settings = {
        className: "center",
        centerMode: true,
        centerPadding: "30px",
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "30px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "40px",
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "20px",
                },
            },
        ],
    };


    return (
        <div className="slider-container relative">
            {result.length > 0 ? (
                <Slider ref={sliderRef} {...settings}>
                    {result.map((result, index) => (
                        <div key={index} className="p-2">
                            <div className="container mx-auto px-6 py-2 bg-white rounded-lg">
                                {/* Uncomment this if you want the "Live" text */}
                                {/* <div className="flex justify-end">
                        <p className="text-sm blink">Live</p>
                    </div> */}
                                <p className="text-xl font-semibold text-gray-900">
                                    {result.reading_value} {result.unit_name}
                                </p>
                                <p className="text-sm text-gray-600">{result.param_name}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="p-2 text-center">
                    <p className="text-lg text-gray-500">Fetching live data from the Oizom sensor installed at SunTeknolozy, Kelambakkam, Chennai</p>
                </div>
            )}


            <button
                className="slick-prev absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-blue-600 rounded-full p-2"
                onClick={() => sliderRef.current.slickPrev()}
            >
                Prev
            </button>
            <button
                className="slick-next absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-blue-600 rounded-full p-2"
                onClick={() => sliderRef.current.slickNext()}
            >
                Next
            </button>

            <style jsx>{`
                .slick-prev:before,
                .slick-next:before {
                    color: black;
                    font-size: 24px;
                }
    
                .slick-prev {
                    left: 10px;
                }
    
                .slick-next {
                    right: 10px;
                }
    
                @keyframes blink {
                    0%, 100% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 0;
                    }
                }
            
                .blink {
                    animation: blink 2s linear infinite;
                    color: #4caf50;
                }
            `}</style>
        </div>
    );

};

export default StickyCarousel;