import React, { useState, useEffect, useRef } from 'react';
import Hero from "./hero/Hero";
import About from "./about/About";
import Clients from "./clients/Clients";
import Address from "../address/Address";
import BusinessVerticals from "./businessVerticals/BusinessVerticals";
import CountDown from "./countDown/CountDown";
import NewNav from "../navbar/NewNav";
import OizomPromotion from "../promotionBanner/oizom";
import StickyCarousel from "../stickyCarousel";

// Home Page
const Home = () => {
  const [isSticky, setIsSticky] = useState(false);
  const heroRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (heroRef.current) {
        const heroPosition = heroRef.current.getBoundingClientRect();
        const heroTop = heroPosition.top;

        if (heroTop <= 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <NewNav />
      <OizomPromotion />
      {isSticky && (
        <div className="sticky top-0 z-50 bg-gray-100 p-4">
          <StickyCarousel />
        </div>
      )}
      <div ref={heroRef}>
        <Hero />
      </div>
      <About />
      <BusinessVerticals />
      <CountDown />
      <Clients />
      <Address />
    </div>
  );
};

export default Home;
