import React from 'react';
import oizom from "../../assets/oizom3d.png";
import CenterModeCarousel from "../CenterModeCarousel";
import backgroundGif from "../../assets/squaredotbg.gif";

const OizomPromotion = () => {
  const getCurrentDate = () => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date().toLocaleDateString('en-US', options);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 w-full p-4 bg-gray-100">
      {/* Left Section (Text content) */}
      <div className="md:col-span-2 bg-white p-8 rounded-l-lg shadow-lg">
        <h1 className="text-2xl font-extrabold leading-8 tracking-tight mb-3 text-gray-900 sm:text-3xl md:text-4xl text-left">
          Oizom Unveils Cutting-Edge Solution for Ambient Air Monitoring
        </h1>
        <p className="text-xl font-medium text-gray-700 mb-5 text-left">
          Revolutionizing Environmental Data with Real-Time Insights
        </p>

        <p className="text-xl font-medium text-gray-900">
          <a
            href="https://www.google.com/maps/place/SUN+TEKNOLOZY/@12.7892458,79.9090307,11z/data=!4m10!1m2!2m1!1ssunteknolozy!3m6!1s0x3a5267030c426185:0x98020b7b1e16afc2!8m2!3d12.7892458!4d80.2139013!15sCgxzdW50ZWtub2xvenmSARhlbnZpcm9ubWVudGFsX2NvbnN1bHRhbnTgAQA!16s%2Fg%2F113hc6hkh?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Sun Teknolozy, Chennai
          </a>
        </p>

        <p className="text-sm text-gray-600">{getCurrentDate()}</p>

        {/* Sensor Readings */}
        <CenterModeCarousel />

        {/* <button className="px-6 py-2 mt-5 text-white bg-green-600 rounded hover:bg-blue-500 text-left">
          Explore More
        </button> */}
      </div>

      {/* Right Section (Image) */}
      <div className="hidden md:flex items-center justify-center col-span-1 relative rounded-r-lg shadow-lg overflow-hidden">
        <div
          className="w-full h-full flex items-center justify-center"
          style={{
            backgroundImage: `url(${backgroundGif})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <img
            src={oizom}
            alt="Oizom Logo"
            className="object-contain w-full h-full max-w-[480px] max-h-[400px] z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default OizomPromotion;
